import App from '@/App.vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import error from '@/plugins/herror'
import toastr from '@meforma/vue-toaster'
import { auth, onAuthStateChanged } from '@/firebase'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createApp } from 'vue'

const { VUE_APP_API_BASE_URL, VUE_APP_RECAPTCHA_SITE_KEY } = process.env

const app = createApp(App)

app.config.globalProperties.window = window

axios.defaults.baseURL = VUE_APP_API_BASE_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.interceptors.request.use(async (req) => {
  if (auth.currentUser) {
    Object.assign(req.headers, {
      'x-api-token': `${await auth.currentUser.getIdToken()}`,
    })
  }
  return req
})

let init = false

onAuthStateChanged(auth, (user) => {
  if (!init) {
    app
      .use(VueReCaptcha, {
        siteKey: VUE_APP_RECAPTCHA_SITE_KEY,
      })
      .use(toastr, {
        position: 'top-right',
        duration: 15000,
      })
      .use(error)
      .use(store)
      .use(router)
      .mount('#app')

    init = true
  }

  if (user) {
    store.state.user = user
  } else {
    store.state.user = null
  }
})

app.config.globalProperties.$http = axios
