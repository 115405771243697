<template>
  <nav
    id="navbar-main"
    aria-label="Primary navigation"
    class="navbar navbar-main navbar-expand-lg navbar-theme-primary navbar-dark navbar-transparent navbar-theme-secondary"
  >
    <div class="container position-relative">
      <a href="#" class="navbar-brand me-lg-5">
        <img src="/assets/img/logo.png" alt="logo" />
      </a>
      <div class="navbar-collapse collapse me-auto">
        <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
          <li class="nav-item">
            <a href="#features" class="nav-link">Features</a>
          </li>
          <li class="nav-item">
            <a href="#pricing" class="nav-link">Pricing</a>
          </li>
          <li class="nav-item">
            <a href="#screenshot" class="nav-link">Screenshot</a>
          </li>
          <li class="nav-item">
            <a href="https://twitter.com/chart_img" target="_twitter" class="nav-link">Twitter</a>
          </li>
          <li class="nav-item">
            <a href="https://medium.com/@chart-img" target="_medium" class="nav-link">Medium</a>
          </li>
          <li class="nav-item">
            <a href="#faq" class="nav-link">FAQ</a>
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'login' }" class="btn btn-tertiary">Sign In</router-link>
      </div>
    </div>
  </nav>
  <main>
    <section class="section-header pb-9 pb-lg-10 mb-4 mb-lg-6 bg-primary text-white">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-lg-7">
            <h1 class="display-1 font-weight-extreme mb-4">CHART-IMG</h1>
            <p class="lead mb-4 mb-lg-5 me-lg-5">
              We provide a reliable API designed to take real-time screenshots of the most popular chart platform
              TradingView.
            </p>
            <a class="btn btn-tertiary" href="https://doc.chart-img.com" target="_doc"
              >API DOCUMENTATION <span class="fas fa-angle-right ms-2"></span
            ></a>
          </div>
          <div class="col-12 col-md-5 d-none d-lg-block text-center">
            <img src="/assets/img/intro.png" alt="intro chart image" />
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg pt-5 mb-4 pb-0">
      <div class="container mt-n8 mt-lg-n10 z-2">
        <div class="row">
          <div class="col-12 col-lg-4 mb-4 mb-lg-0">
            <div class="card bg-white shadow border-gray-300 p-4">
              <div class="card-body p-3">
                <div class="icon icon-lg icon-primary justify-content-start mb-3">
                  <span class="fas fa-bolt"></span>
                </div>
                <h2 class="mb-4">Quick and Simple</h2>
                <p>Standard RESTful API design with URL query makes parsing quick and straightforward.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 mb-4 mb-lg-0">
            <div class="card bg-white shadow border-gray-300 p-4">
              <div class="card-body p-3">
                <div class="icon icon-lg icon-primary justify-content-start mb-3">
                  <span class="far fa-clock"></span>
                </div>
                <h2 class="mb-4">Real-Time Chart</h2>
                <p>Access to real-time market chart for timely decision-making and market analysis.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 mb-4 mb-lg-0">
            <div class="card bg-white shadow border-gray-300 p-4">
              <div class="card-body p-3">
                <div class="icon icon-lg icon-primary justify-content-start mb-3">
                  <span class="fas fa-code"></span>
                </div>
                <h2 class="mb-4">Free API</h2>
                <p>
                  With the help of a free API, you can do testing and create flexible, powerful apps in record time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row justify-content-center mb-4 mb-lg-5">
          <div class="col-12 col-md-10 col-lg-8 text-center" id="features">
            <h2>Features</h2>
            <p class="lead">Supported TradingView Charts</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="row justify-content-center">
              <div class="col-12 col-md-6">
                <div class="row mt-5 mt-lg-0 px-3">
                  <div class="card shadow border-gray-300">
                    <div class="card-body p-4">
                      <img src="/assets/img/screenshot/screenshot-1.png" alt="MINI Chart Image" class="mb-4" />
                      <h2 class="h4 mb-2">MINI CHART</h2>
                      <p>
                        Mini Chart captures the screenshot of simplified TradingView area chart with the price and
                        change percentage.
                      </p>
                      <a href="https://doc.chart-img.com/#mini-chart" target="_doc" class="btn btn-sm btn-primary"
                        >Read API Documentation</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-lg-5">
                <div class="row mt-3 mt-lg-0 px-3">
                  <div class="card shadow border-gray-300">
                    <div class="card-body p-4">
                      <img src="/assets/img/screenshot/screenshot-3.png" alt="Advanced Chart Image" class="mb-4" />
                      <h2 class="h4 mb-2">ADVANCED CHART v1</h2>
                      <p>
                        Advanced Chart captures the screenshot of TradingView chart with over 60+ customizable technical
                        indicators.
                      </p>
                      <a href="https://doc.chart-img.com/#advanced-chart" target="_doc" class="btn btn-sm btn-primary"
                        >Read API Documentation</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="row justify-content-center">
              <div class="col-12 col-md-6">
                <div class="row mt-5 mt-lg-0 px-3">
                  <div class="card shadow border-gray-300">
                    <div class="card-body p-4">
                      <img src="/assets/img/screenshot/screenshot-7.png" alt="Advanced Chart Image" class="mb-4" />
                      <h2 class="h4 mb-2">ADVANCED CHART v2</h2>
                      <p>
                        Advanced Chart captures the screenshot of TradingView chart with over 90+ customizable technical
                        indicators and drawing tools.
                      </p>
                      <a
                        href="https://doc.chart-img.com/#advanced-chart-nbsp"
                        target="_doc"
                        class="btn btn-sm btn-primary"
                        >Read API Documentation</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-lg-5">
                <div class="row mt-3 mt-lg-0 px-3">
                  <div class="card shadow border-gray-300">
                    <div class="card-body p-4">
                      <img src="/assets/img/screenshot/screenshot-9.png" alt="Advanced Chart Image" class="mb-4" />
                      <h2 class="h4 mb-2">LAYOUT CHART</h2>
                      <p>Shared Layout captures the screenshot of shared TradingView chart by layout ID.</p>
                      <a href="https://doc.chart-img.com/#shared-layout" target="_doc" class="btn btn-sm btn-primary"
                        >Read API Documentation</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg bg-gray-200">
      <div class="container">
        <div class="row justify-content-center mb-4 mb-lg-5">
          <div class="col-12 col-md-10 col-lg-8 text-center" id="pricing">
            <h2>Pricing Plans</h2>
            <p class="lead">14 days money back guarantee</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="card shadow border-gray-300 text-center py-4">
              <div class="card-header border-0 bg-white p-3">
                <h2 class="h3 text-primary mb-4">BASIC</h2>
                <span class="d-block"
                  ><span class="display-2 text-dark fw-bold"><span class="align-top font-medium">$</span>0 </span
                  ><span class="d-block text-gray font-small">/ month</span></span
                >
              </div>
              <div class="card-body">
                <ul class="list-unstyled mb-4">
                  <li class="list-item pb-3">
                    <strong>REST API</strong>
                  </li>
                  <li class="list-item pb-3">
                    Daily Limit<br />
                    <strong>50</strong>
                  </li>
                  <li class="list-item pb-3">
                    Rate Limit<br />
                    <strong>1</strong> / sec
                  </li>
                  <li class="list-item pb-3">
                    Watermark<br />
                    <strong>Yes</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Parameter<br />
                    <strong>3</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Resolution<br />
                    <strong>800 x 600</strong>
                  </li>
                </ul>
                <div class="d-grid">
                  <router-link :to="{ name: 'login' }" class="btn btn-primary">SELECT</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="card shadow border-gray-300 text-center py-4">
              <div class="card-header border-0 bg-white p-3">
                <h2 class="h3 text-primary mb-4">PRO</h2>
                <span class="d-block"
                  ><span class="display-2 text-dark fw-bold"><span class="align-top font-medium">$</span>7 </span
                  ><span class="d-block text-gray font-small">/ month</span></span
                >
              </div>
              <div class="card-body">
                <ul class="list-unstyled mb-4">
                  <li class="list-item pb-3">
                    <strong>REST API</strong>
                  </li>
                  <li class="list-item pb-3">
                    Daily Limit<br />
                    <strong>500</strong>
                  </li>
                  <li class="list-item pb-3">
                    Rate Limit<br />
                    <strong>10</strong> / sec
                  </li>
                  <li class="list-item pb-3">
                    Watermark<br />
                    <strong>No</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Parameter<br />
                    <strong>5</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Resolution<br />
                    <strong>1920 x 1080</strong>
                  </li>
                </ul>
                <div class="d-grid">
                  <router-link :to="{ name: 'login' }" class="btn btn-primary">SELECT</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="card shadow border-gray-300 text-center py-4">
              <div class="card-header border-0 bg-white p-3">
                <h2 class="h3 text-primary mb-4">MEGA</h2>
                <span class="d-block"
                  ><span class="display-2 text-tertiary fw-bold"><span class="align-top font-medium">$</span>10 </span
                  ><span class="d-block text-gray font-small">/ month</span></span
                >
              </div>
              <div class="card-body">
                <ul class="list-unstyled mb-4">
                  <li class="list-item pb-3">
                    <strong>REST API</strong>
                  </li>
                  <li class="list-item pb-3">
                    Daily Limit<br />
                    <strong>1,000</strong>
                  </li>
                  <li class="list-item pb-3">
                    Rate Limit<br />
                    <strong>15</strong> / sec
                  </li>
                  <li class="list-item pb-3">
                    Watermark<br />
                    <strong>No</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Parameter<br />
                    <strong>10</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Resolution<br />
                    <strong>1920 × 1600</strong>
                  </li>
                </ul>
                <div class="d-grid">
                  <router-link :to="{ name: 'login' }" class="btn btn-tertiary">BEST VALUE</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="card shadow border-gray-300 text-center py-4">
              <div class="card-header border-0 bg-white p-3">
                <h2 class="h3 text-primary mb-4">ULTRA</h2>
                <span class="d-block"
                  ><span class="display-2 text-dark fw-bold"><span class="align-top font-medium">$</span>20 </span
                  ><span class="d-block text-gray font-small">/ month</span></span
                >
              </div>
              <div class="card-body">
                <ul class="list-unstyled mb-4">
                  <li class="list-item pb-3">
                    <strong>REST API</strong>
                  </li>
                  <li class="list-item pb-3">
                    Daily Limit<br />
                    <strong>3,000</strong>
                  </li>
                  <li class="list-item pb-3">
                    Rate Limit<br />
                    <strong>35</strong> / sec
                  </li>
                  <li class="list-item pb-3">
                    Watermark<br />
                    <strong>No</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Parameter<br />
                    <strong>25</strong>
                  </li>
                  <li class="list-item pb-3">
                    Max Resolution<br />
                    <strong>2048 x 1920</strong>
                  </li>
                </ul>
                <div class="d-grid">
                  <router-link :to="{ name: 'login' }" class="btn btn-primary">SELECT</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg">
      <div class="container">
        <div class="row justify-content-center mb-4 mb-lg-5">
          <div class="col-12 col-md-10 col-lg-8 text-center" id="screenshot">
            <h2>Real-time Screenshot</h2>
            <p class="lead">Create customized real-time screenshots via API</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-1.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-2.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-3.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-4.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-5.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-6.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-7.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-8.png" alt="project chart-img" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <div class="card shadow bg-white border-gray-300 p-3 rounded">
              <img src="/assets/img/screenshot/screenshot-9.png" alt="project chart-img" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg bg-gray-300" id="faq">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-md-8 mb-5">
            <h2>FAQs</h2>
            <p class="lead">Frequently Asked Questions</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="accordion bg-white rounded" id="accordionFAQs">
              <div class="accordion-item">
                <h2 class="accordion-header" id="accordionSignUp">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSignUp"
                    aria-expanded="true"
                    aria-controls="collapseSignUp"
                  >
                    <strong>How do I sign up?</strong>
                  </button>
                </h2>
                <div
                  id="collapseSignUp"
                  class="accordion-collapse collapse show"
                  aria-labelledby="accordionSignUp"
                  data-bs-parent="#accordionFAQs"
                >
                  <div class="accordion-body">
                    We use Google sign-in to manage web authentication. No sign-up or registration is required. Simply
                    sign-in with your Google account.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="accordionFreePlan">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFreePlan"
                    aria-expanded="false"
                    aria-controls="collapseFreePlan"
                  >
                    <strong>Do you offer a free subscription plan?</strong>
                  </button>
                </h2>
                <div
                  id="collapseFreePlan"
                  class="accordion-collapse collapse"
                  aria-labelledby="accordionFreePlan"
                  data-bs-parent="#accordionFAQs"
                >
                  <div class="accordion-body">
                    Yes. The Basic Plan is free for anyone to use. Sign-in with your Google account, and you will
                    immediately gain access to the service with your new API Key. Please do not create multiple
                    accounts, or you will be banned without notice.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="accordionSubscriptionPlan">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSubscriptionPlan"
                    aria-expanded="false"
                    aria-controls="collapseSubscriptionPlan"
                  >
                    <strong>How do I cancel my paid subscription plan?</strong>
                  </button>
                </h2>
                <div
                  id="collapseSubscriptionPlan"
                  class="accordion-collapse collapse"
                  aria-labelledby="accordionSubscriptionPlan"
                  data-bs-parent="#accordionFAQs"
                >
                  <div class="accordion-body">
                    Your paid subscription will automatically renew until you cancel it. You can always cancel it by
                    going to "Subscription" in the menu then clicking the "cancel subscription" button. After you
                    cancel, you will still have access to all the features until the end of your billing cycle.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="accordionChangePlan">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseChangePlan"
                    aria-expanded="false"
                    aria-controls="collapseChangePlan"
                  >
                    <strong>Can I change my plan?</strong>
                  </button>
                </h2>
                <div
                  id="collapseChangePlan"
                  class="accordion-collapse collapse"
                  aria-labelledby="accordionChangePlan"
                  data-bs-parent="#accordionFAQs"
                >
                  <div class="accordion-body">
                    If you need a higher request limit with a customized enterprise plan, please contact us at
                    support@chart-img.com.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="accordionRefund">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseRefund"
                    aria-expanded="false"
                    aria-controls="collapseRefund"
                  >
                    <strong>How does a refund work?</strong>
                  </button>
                </h2>
                <div
                  id="collapseRefund"
                  class="accordion-collapse collapse"
                  aria-labelledby="accordionRefund"
                  data-bs-parent="#accordionFAQs"
                >
                  <div class="accordion-body">
                    Our refund policy is simple. Within 14 days, if you are unsatisfied with the service, we will refund
                    you the full amount.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="PaymentRefund">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsePayment"
                    aria-expanded="false"
                    aria-controls="collapsePayment"
                  >
                    <strong>How is my payment being processed?</strong>
                  </button>
                </h2>
                <div
                  id="collapsePayment"
                  class="accordion-collapse collapse"
                  aria-labelledby="accordionPayment"
                  data-bs-parent="#accordionFAQs"
                >
                  <div class="accordion-body">
                    We use Stripe to process your payment. It is the same payment provider used by Twitter, Pinterest,
                    and Lyft. We do not handle your credit card information directly.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</template>
<script>
import Footer from '@/components/Footer'

const { VUE_APP_PUB_API_KEY, VUE_APP_API_URL } = process.env

export default {
  components: {
    Footer,
  },
  computed: {
    apiKey() {
      return VUE_APP_PUB_API_KEY
    },
    apiUrl() {
      return VUE_APP_API_URL
    },
  },
}
</script>
