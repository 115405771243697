<template>
  <footer class="footer pt-6 pb-5 bg-dark text-white">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <span class="h5">About</span>
          <p class="mt-3">
            We provide a fast and reliable API designed to take real-time screenshots of the most popular chart platform
            TradingView.
          </p>
          <ul class="social-buttons mb-5 mb-lg-0">
            <li>
              <a
                href="https://twitter.com/chart_img"
                target="_twitter"
                aria-label="twitter social link"
                class="icon-white me-3"
                ><span class="fab fa-twitter"></span
              ></a>
            </li>
            <li>
              <a
                href="https://medium.com/@chart-img"
                target="_medium"
                aria-label="medium social link"
                class="icon-white me-3"
                ><span class="fab fa-medium"></span
              ></a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3 mb-5 mb-lg-0 text-right">
          <span class="h5">Service</span>
          <ul class="footer-links mt-2">
            <li>
              <router-link :to="{ name: 'subscription' }">Subscription</router-link>
            </li>
            <li>
              <a href="https://doc.chart-img.com" target="_doc">API Documentation</a>
            </li>
            <li>
              <a href="https://status.chart-img.com" target="_status">API Status</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3 mb-5 mb-lg-0 text-right">
          <span class="h5">Other</span>
          <ul class="footer-links mt-2">
            <li>
              <a href="mailto:support@chart-img.com" target="_contact">Contact Us</a>
            </li>
            <li><a href="/terms" target="_terms">Terms of Service</a></li>
            <li><a href="/privacy" target="_blank">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <hr class="bg-secondary my-3 my-lg-5" />
      <div class="row">
        <div class="col mb-md-0">
          <div class="d-flex text-center justify-content-center align-items-center" role="contentinfo">
            <p class="fw-normal font-small mb-0">
              CHART-IMG <span class="current-year">© 2024</span><br />DEVELOPED BY
              <a href="https://hawooni.com" target="_hawooni">HAWOONI</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
