import { createRouter, createWebHistory } from 'vue-router'
import MiddlewarePipeline from './middleware'
import Home from '@/views/Home.vue'
import Terms from '@/views/Terms.vue'
import Privacy from '@/views/Privacy.vue'
import auth from '@/router/middleware/auth'
import guest from '@/router/middleware/guest'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
  },
  {
    path: '/account/subscription',
    name: 'subscription',
    meta: {
      middleware: [auth],
    },
    component: () => import('../views/account/Subscription.vue'),
  },
  {
    path: '/account/notification',
    name: 'notification',
    meta: {
      middleware: [auth],
    },
    component: () => import('../views/account/Notification.vue'),
  },
  {
    path: '/account/api',
    name: 'api',
    meta: {
      middleware: [auth],
    },
    component: () => import('../views/account/API.vue'),
  },
  {
    path: '/signin',
    name: 'login',
    meta: {
      middleware: [guest],
    },
    component: () => import('../views/Login.vue'),
  },
  { path: '/:pathMatch(.*)*', component: () => import('../views/404.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// ref: https://blog.logrocket.com/vue-middleware-pipelines/
router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware
  const context = { to, from, next, store }

  if (!middleware) {
    return next()
  }

  return middleware[0]({
    ...context,
    next: MiddlewarePipeline(context, middleware, 1),
  })
})

export default router
