import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signInWithRedirect, getRedirectResult } from 'firebase/auth' // prettier-ignore
import { initializeApp } from 'firebase/app'

const configFirebase = {
  projectId: process.env.VUE_APP_PROJECT_ID,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
}

const firebase = initializeApp(configFirebase)
const auth = getAuth(firebase)

export {
  firebase,
  auth,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
}
