<template>
  <main class="bg-gray-200">
    <section class="section-header pb-9 pb-lg-12 mb-4 mb-lg-6">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 text-center">
            <h1 class="display-2 mb-3">Privacy Policy</h1>
            <p class="mb-0">Updated January 19th, 2022</p>
          </div>
        </div>
      </div>
      <div class="pattern bottom"></div>
    </section>
    <section class="section section-lg pt-0">
      <div class="container mt-n7 mt-lg-n12 z-2">
        <div class="row justify-content-center">
          <div class="col">
            <div class="card border-gray-300 p-4 p-md-5">
              <p>
                CHART-IMG's policy is to respect your privacy regarding any information we may collect from you across
                our website and/or API at chart-img.com.
              </p>
              <p>
                This page informs users regarding our policies with the collection, use, and disclosure of Personal
                Information if anyone decides to use our service. If you choose to use our service, you agree to the
                collection and use of information in relation to this policy. The personal information we collect is
                used for providing and improving the service. We will not use or share your information with anyone
                except as described in this policy.
              </p>
              <p>
                The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
                accessible at CHART-IMG unless otherwise defined in this Privacy Policy.
              </p>
              <h2 class="mt-5 mb-3">Information Collection and Use</h2>
              <p>
                For a better experience, while using our service, we may require you to provide us with certain
                personally identifiable information, such as but not limited to your email address. The information we
                request will be retained by us and used as described in this privacy policy.
              </p>
              <h2 class="mt-5 mb-3">Cookies</h2>
              <p>
                Cookies are files with a small amount of data that are commonly used as unique anonymous identifiers.
                These are sent to your browser from the websites that you visit and are stored on your device's internal
                memory. This service does not use these "cookies" explicitly. However, the service may use third-party
                code and libraries that use "cookies" to collect information and improve their services.
              </p>
              <h2 class="mt-5 mb-3">Service Providers</h2>
              <p>We may employ third-party companies and individuals due to the following reasons:</p>
              <ul>
                <li>To facilitate our service;</li>
                <li>To provide the service on our behalf;</li>
                <li>To perform service-related services; or</li>
                <li>To assist us in analyzing how our service is used.</li>
              </ul>
              <p>Third parties will not have access to your personal information unless specifically needed.</p>
              <h2 class="mt-5 mb-3">Security</h2>
              <p>
                We value your trust in providing us with your personal information, and thus we are striving to use
                commercially acceptable means of protecting it. However, no method of transmission over the internet or
                method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
              </p>
              <h2 class="mt-5 mb-3">Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on
                this page. These changes are effective immediately after they are posted on this page.
              </p>
              <h2 class="mt-5 mb-3">Contact Us</h2>
              <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
