const MSG_ERROR = 'Something went wrong.'

export default {
  install: (app) => {
    app.config.globalProperties.$error = (err) => {
      if (err.response?.data) {
        const { errors, error, message } = err.response.data

        if (errors) {
          errors.forEach((edata) => {
            app.$toast.error(edata.message)
          })
        } else {
          app.$toast.error(error || message || MSG_ERROR)
        }
      } else {
        app.$toast.error(MSG_ERROR)
      }
    }
  },
}
